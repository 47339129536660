<template>
  <v-autocomplete
    class="select-timezone"
    :chips="!!value"
    :value="value"
    :items="timezones"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="disabled || loading"
    v-bind="$attrs"
    @input="onChange"
  />
</template>

<script>
import axios from 'axios'
import SnackbarMixin from '@/utils/mixins/Snackbar'

export default {
  name: 'TimeZoneSelect',

  mixins: [SnackbarMixin],

  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select a time zone',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timezones: [],
      loading: false,
    }
  },
  methods: {
    async fetchTimeZones() {
      this.loading = true
      try {
        const response = await axios.get(
          'https://worldtimeapi.org/api/timezone'
        )
        this.timezones = response.data // Array of time zone strings
      } catch (error) {
        this.showSnackbar('Error fetching time zones:', error)
      } finally {
        this.loading = false
      }
    },
    onChange(value) {
      this.$emit('input', value) // Emit the selected value
    },
  },
  created() {
    this.fetchTimeZones()
  },
}
</script>

<style scoped>
.select-timezone {
  width: 100%;
}
</style>
